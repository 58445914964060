class projectLogos {

  constructor(elem) {
    this.init(elem);
    if (!document.body.classList.contains('home')) this.scrollTo();
  }

  init(swiper) {
   new Swiper(swiper, {
      slidesPerView: 2,
      spaceBetween: 2,
      speed: 900,
      // slidesPerView: 6,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 5,
        },
        1440: {
          slidesPerView: 4,
        },
        1920: {
          slidesPerView: 5,
        },
        2560: {
          slidesPerView: 6,
        },
      },
      autoplay: {
        delay: 1500,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: swiper.querySelector('.swiper-button-next'),
        prevEl: swiper.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: swiper.querySelector('.swiper-pagination'),
        clickable: true,
        type: 'fraction',
      },

      on: {
        init: function () {
          /* do something */

          // Timeline Variables
          let
            slides = swiper.querySelector(".project-logos__slide"),
            navItems = swiper.querySelector(".project-logos__nav > div"),
            tl = new TimelineMax({
              delay: 0
            });

          TweenLite.defaultEase = Power1.easeOut;

          // Create Animation Timeline
          tl
            .to(swiper, 0.001, {
              autoAlpha: 1,
            }, "a")
            .staggerFrom(slides, 0.9, {
              autoAlpha: 0,
              x: "+=15"
            }, 0.15, "b")
            .staggerFrom(navItems, 0.9, {
              autoAlpha: 0,
              x: "+=15º"
            }, 0.15, "b");

        },
      }
    });
  }

  scrollTo() {
    $('.project-logos__link').click(function (e) {
      e.preventDefault();
      let target = $(e.currentTarget);
      let anchor = target.attr('href');

      $('html, body').animate({
        scrollTop: $(anchor).offset().top
      }, 1000);
    });
  }

}

export default projectLogos;
