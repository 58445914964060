export default class ContactForm {
  constructor(c) {
    // GSAP default easing
    gsap.defaults({
      ease: "power4.out",
      duration: 0.3,
    });

    // Variables
    this.$form = c;
    this.formAction = this.$form.getAttribute("action");
    this.formMethod = this.$form.getAttribute("method");
    this.$wrapper = c.parentNode;
    this.$submitBtn = c.querySelector('button[type="submit"]');

    // Events
    this.handleSubmit();
  }

  // Fetch form
  // --------------

  async handleSubmit() {
    // On submit button click
    this.$form.addEventListener("submit", async (e) => {
      e.preventDefault();
      e.stopPropagation();

      // Disable submit button
      this.$submitBtn.disabled = true;

      // Get the form data
      const formData = new FormData(this.$form);

      try {
        const res = await this.sendFormData(formData);

        // Enable submit button
        this.$submitBtn.disabled = false;

        // Show Step errors and abort
        if (res.alert) {
          // If res.alert is a string (error message), show fail message. Otherwise set errors on the form fields.
          if (typeof res.alert === "string") {
            this.showFail(res.alert);
          } else {
            this.setErrors(res.alert);
          }
          return false;
        }

        // Continue to next step
        this.showSuccess();
        return true;
      } catch (error) {
        console.error("Submission error:", error);
        this.showFail(error.message);
        return false;
      }
    });
  }

  // Send data to controller
  async sendFormData(formData) {
    try {
      const response = await fetch(this.formAction, {
        method: this.formMethod,
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      throw new Error("Network error occurred. Please try again later.", error);
    }
  }

  // Show fail message
  // --------------

  showFail() {
    // Animate success message
    const $fail = document.querySelector(".form__result--fail");
    $fail.classList.add("active");
    gsap.from($fail, {
      opacity: 0,
      y: "+=16",
    });

    // But keep the button disabled
    this.$submitBtn.disabled = true;
  }

  // Show success message and hide the form
  // --------------

  showSuccess() {
    // Animate success message
    const $success = document.querySelector(".form__result--success");
    $success.classList.add("active");
    this.$wrapper.classList.add("success");
    gsap.from($success, {
      opacity: 0,
      y: "+=16",
    });

    // But keep the button disabled
    this.$submitBtn.disabled = true;

    // Scroll to top of form not using gsap
    window.scrollTo({
      top: this.$form.offsetTop,
      behavior: "smooth",
    });
  }

  // Take the server side validation and add errors to the input fields
  // --------------

  setErrors(errors) {
    // Remove previous/old error messages
    this.removeErrors();

    console.log("Errors: ", errors);

    for (const field in errors) {
      // Add invalid class
      let input = this.$form.querySelector(`#${field}`);
      if (input) input.classList.add("invalid");

      // Create and append error message
      let errorMessage = document.createElement("span");
      errorMessage.innerText = errors[field];
      errorMessage.classList.add("error-message");
      if (input) {
        input.parentNode.appendChild(errorMessage);
      }
    }
  }

  // Clean up old error messages
  // --------------

  removeErrors() {
    // Remove previous/old invalid classes
    let oldErrors = this.$form.querySelectorAll(".invalid");
    oldErrors.forEach((oe) => {
      oe.classList.remove("invalid");
    });

    // Remove previous/old error messages
    let oldErrorMessages = this.$form.querySelectorAll(".error-message");
    oldErrorMessages.forEach((em) => {
      em.remove();
    });
  }
}
