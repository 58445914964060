class TeaserLinks {

  constructor(c) {
    this.trigger = c;
    c.addEventListener('click', (e) => {
      let element = e.currentTarget;
      while (element && !element.classList.contains('teaserlinks')) {
        element = element.parentNode;
      }
      let gallery = element;
      if (gallery) {
        gallery.classList.toggle('show-all');
      }
    });
  }
}

export default TeaserLinks;
