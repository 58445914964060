class consultingSlider {

  constructor(elem) {
    this.swiper = null;
    this.elem = elem;
    this.swiper = null;
    this.init();
  }

  init() {
    let spv1600 = this.elem.classList.contains('narrow') ? 2 : 3;
    let spv1920 = this.elem.classList.contains('narrow') ? 2 : 3;
    let spv2500 = this.elem.classList.contains('narrow') ? 2 : 4;
    let spv = this.elem.classList.contains('narrow') ? 2 : 4;
    let ap = this.elem.classList.contains('autoplay') ? {
      delay: 6000,
    } : false;

    let swiperOptions = {
      speed: 600,
      loop: false,
      autoplay: ap,
      roundLengths: true,
      grabCursor: true,
      pagination: {
        el: this.elem.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: this.elem.querySelector('.swiper-button-next'),
        prevEl: this.elem.querySelector('.swiper-button-prev'),
      },
      slidesPerView: spv,
      spaceBetween: 32,
      breakpoints: {
        2500: {
          slidesPerView: spv2500,
          spaceBetween: 64,
        },
        1920: {
          slidesPerView: spv1920,
          spaceBetween: 48,
        },
        1600: {
          slidesPerView: spv1600,
          spaceBetween: 48,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        576: {
          slidesPerView: 1,
          spaceBetween: 16,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 12,
        }
      }
    };

    new Swiper(this.elem, swiperOptions);
  }
}

export default consultingSlider;
