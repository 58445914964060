class Newsletter {

  constructor() {
    this.newsletterModalDOM = document.getElementById('newsletterModal');
    this.newsletterFieldDOM = document.getElementById('newsletter');
    this.newsletterTriggerDOM = this.newsletterFieldDOM.querySelector('.newsletter__submit');
    this.newsletterModalTL = null;

    // Load script to jQuery
    jQuery.loadScript = function (url, callback) {
      jQuery.ajax({
        url: url,
        dataType: 'script',
        success: callback,
        async: true
      });
    }

    // Load script only when newsletter field is in viewport
    ScrollTrigger.create({
      trigger: this.newsletterFieldDOM,
      once: true,
      start: "top bottom",
      // markers: true,
      onEnter: () => {

        // Load Mailchimp script
        $.loadScript('//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js', () => {
          this.validateForm();
        });

        // this.loadScript(this.validateForm());
        this.initModal();
      },
    });
  }

  // Load Mailchimp script
  // loadScript(callback) {
  //   console.log("Load Newsletter Script");
  //   var script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.async = true;
  //   script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
  //   script.onload = callback;
  //   document.getElementsByTagName('head')[0].appendChild(script);
  // }


  // Initialize Modal
  initModal() {

    // Set animation Timeline
    let
      backdrop = this.newsletterModalDOM.querySelector('.modal__backdrop'),
      dialog = this.newsletterModalDOM.querySelector('.modal-dialog'),
      tl = gsap.timeline({
        onStart: () => {
          this.newsletterModalDOM.classList.add('show');
          this.newsletterModalDOM.getAttribute('aria-hidden', 'false');
        },
        onReverseComplete: () => {
          this.newsletterModalDOM.classList.remove('show');
          this.newsletterModalDOM.getAttribute('aria-hidden', 'true');
        }
      });
    tl.from(backdrop, {
      duration: 0.3,
      opacity: 0,
      ease: "Power4.out"
    }, "a")
    tl.from(dialog, {
      duration: 0.3,
      autoAlpha: 0,
      y: "+=24",
      ease: "Power4.out",
    }, "a")
    tl.pause();
    this.newsletterModalTL = tl;

    // Add trigger to open modal
    this.newsletterTriggerDOM.addEventListener('click', (e) => {
      e.preventDefault();

      // Copy email from footer to modal
      let emailInputValue = document.querySelector('.newsletter__email--footer').value;
      document.getElementById('mce-EMAIL').value = emailInputValue;

      // Open modal
      this.openModal();
      this.validate();

    });

    // Add trigger to close modal on close button
    this.newsletterModalDOM.querySelector('.close').addEventListener('click', (e) => {
      this.closeModal();
    });

    // Add trigger to close modal on outside click
    this.newsletterModalDOM.querySelector('.modal__backdrop').addEventListener('click', (e) => {
      this.closeModal();
    });
  }


  // Open Modal using Timeline
  openModal() {
    this.newsletterModalTL.play();
  }


  // Close Modal using Timeline
  closeModal() {
    this.newsletterModalTL.reverse(0);
  }


  // Validate??
  validate() {
    // console.log("validate");
    window.addEventListener('load', function () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      let forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      let validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener('submit', function (event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
    }, false);
  }


  // Validate with custom messages
  validateForm() {
    // console.log("validate form");
    (function ($) {
      window.fnames = new Array();
      window.ftypes = new Array();
      fnames[0] = 'EMAIL';
      ftypes[0] = 'email';
      fnames[14] = 'SALUTATION';
      ftypes[14] = 'dropdown';
      fnames[16] = 'TITLE';
      ftypes[16] = 'text';
      fnames[1] = 'FNAME';
      ftypes[1] = 'text';
      fnames[2] = 'LNAME';
      ftypes[2] = 'text';
      fnames[6] = 'JOB';
      ftypes[6] = 'text';
      fnames[7] = 'ORGA';
      ftypes[7] = 'text';
      fnames[4] = 'LANG';
      ftypes[4] = 'dropdown';
    }(jQuery));
    var $mcj = jQuery.noConflict(true);

    /**
        To customize your embedded form validation messages, place this code before the closing script tag.
    */
    $mcj.extend($mcj.validator.messages, {
      required: "Bitte füllen Sie dieses Feld aus",
      remote: "Please fix this field.",
      email: "Bitte tragen Sie eine gültige Email Adresse ein",
      url: "Please enter a valid URL.",
      date: "Please enter a valid date.",
      dateISO: "Please enter a valid date (ISO).",
      number: "Please enter a valid number.",
      digits: "Please enter only digits.",
      creditcard: "Please enter a valid credit card number.",
      equalTo: "Please enter the same value again.",
      accept: "Please enter a value with a valid extension.",
      maxlength: $mcj.validator.format("Please enter no more than {0} characters."),
      minlength: $mcj.validator.format("Please enter at least {0} characters."),
      rangelength: $mcj.validator.format("Please enter a value between {0} and {1} characters long."),
      range: $mcj.validator.format("Please enter a value between {0} and {1}."),
      max: $mcj.validator.format("Please enter a value less than or equal to {0}."),
      min: $mcj.validator.format("Please enter a value greater than or equal to {0}."),
      mc_birthday: "Please enter a valid month and day.",
      mc_date: "Please enter a valid date.",
      mc_phone: "Please enter a valid phone number.",
    });
  }

}

export default Newsletter;
