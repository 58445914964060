class Stepper {

    constructor(els) {
        gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

        ScrollTrigger.defaults({
            toggleActions: "restart pause resume pause"
        });

        els.forEach(s => {
            this.init(s);
        });



    }

    init(s) {

        const panels = gsap.utils.toArray(".panel", s);
        const pin = s.querySelector('.pin');
        const lastPanel = panels[panels.length - 1];

        /** 
         * Sticky path
         */
        ScrollTrigger.create({
            trigger: pin,
            start: "top top",
            startTrigger: lastPanel,
            endTrigger: lastPanel,
            end: "top top",
            pin: true,
            // markers: true
        });

        /**
         * Scrolltrigger for panels
         */

        panels.forEach(panel => {
            ScrollTrigger.create({
                trigger: panel,
                start: "top top",
                end: "bottom",
                // markers: {startColor: "white", endColor: "white"},
                onEnter: () => findActiveLink(panel),
                onEnterBack: () => findActiveLink(panel),
            });
        });

        function findActiveLink(p) {
            let path = s.querySelector('.path');
            let link = path.querySelectorAll("a[href='#" + p.id + "']")[0];
            // console.log(link);
            setActiveStep(link);
            setLinePosition(link);
        }

        function setLinePosition(el) {
            // console.log(el.offsetTop);
            let hr = s.querySelector("hr.default");
            // console.log("top: ", pin.clientHeight, el.offsetTop);
            // hr.style.top = el.offsetTop + "px";
            // hr.style.top = 50 + "px";
            hr.style.height = pin.clientHeight -  el.offsetTop + "px";
        }

        // Set links
        function setActiveStep(el) {
            const steps = document.querySelectorAll(".step");
            // console.log("---------")
            let hit = false;
            steps.forEach(step => {
                if (el.isSameNode(step)) {
                    step.classList.add('active');
                    hit = true;
                    // console.log("active:", step, hit);
                } else {
                    // console.log("visited: ", step, hit);
                    step.classList.add('visited');
                    step.classList.remove('active');
                    if (hit) {
                        step.classList.remove('visited');
                        // console.log("remove visited", step);
                        return false;
                    }
                }
            });
        }

        /**
         * On link clicks
         */

        const steps = s.querySelectorAll('.steps .step');
        steps.forEach(step => {
            step.addEventListener('click', (e) => {
                e.preventDefault();
                const link = e.target.parentElement;
                const panel = document.querySelector(e.target.parentElement.getAttribute("href"));
                if (link && panel && link.classList.contains('step')) {
                    let offset = panel.offsetTop + s.getBoundingClientRect().top + window.scrollY;
                    gsap.to(window, {
                        scrollTo: {
                            y: offset + 2,
                            autoKill: false
                        },
                        duration: 0.9,
                    });
                    setActiveStep(step);
                    setLinePosition(step);
                }
            });
        });

        // Set initial line position
        setLinePosition(s.querySelectorAll('.steps .step')[0]);
    }

}

export default Stepper;
