import drawSVG from '../vendor/gsap/DrawSVGPlugin';

// Avoid tree shaking error in production build
const plugins = [ drawSVG ];

class MobileNav {

	constructor() {	
		gsap.registerPlugin(drawSVG);

		// Variables 
		this.navBtn = $('.btn-mobile-nav');
		this.navMenu = $('.nav-mobile');

		// Timeline variables
		this.navBtnTl = gsap.timeline({onComplete: this.navBtnState, onCompleteParams: this.navMenu, onReverseComplete: this.deactivateMobileMenu, onReverseCompleteParams: this.navMenu});
		this.navMenuTl = gsap.timeline();

		// Initialize timeline animations
		this.init();
		this.events();

	}


	init() {

		let that = this;

		that.navBtn.css("opacity", 1);
		TweenLite.defaultEase = Power2.easeOut;

		// build menu animation
		let 
			mobileBg = 			that.navMenu.find('.nav-mobile__bg'),
			mobileLogo = 		that.navMenu.find('.logo'),
			mobileLinks = 		that.navMenu.find('.nav-main .nav-main__item'),
			langLinks = 		that.navMenu.find('.nav-meta__lang'),
			mobileContact = 	that.navMenu.find('.nav-contact');

		that.navMenuTl.to(that.navMenu, 0.01, { css: {display: "flex"} });
		that.navMenuTl.from(mobileBg, 0.3, { autoAlpha: 0 }, "a");
		that.navMenuTl.from(mobileLogo, 0.3, { autoAlpha: 0 }, "a");
		that.navMenuTl.staggerFrom(mobileLinks, 0.3, { y: "+=15", autoAlpha: 0 }, 0.05, "a+=0.15");
		that.navMenuTl.from(langLinks, 0.3, { y: "+=15", autoAlpha: 0 }, "a+=0.45");
		that.navMenuTl.from(mobileContact, 0.6, { y: "+=15", autoAlpha: 0, clearProps: "transform" }, "a+=0.45");
		that.navMenuTl.pause();


		// build button animation
		let
			navBtnSvg = 		that.navBtn.find('#burger-icn-svg'),
			burger = 			navBtnSvg.find('#burger line'),
			closeUp = 			navBtnSvg.find('#close line#up'),
			closeDown = 		navBtnSvg.find('#close line#down');

		that.navBtnTl.staggerFromTo(burger, 0.6, { x: "0", drawSVG: "0% 100%" }, { x: "+=10", drawSVG: "0% 0%" }, 0.1);
		that.navBtnTl.fromTo(closeDown, 0.6, { x: "-=10", y: "-=10", drawSVG: "100% 100%" }, { x: "0", y: "0", drawSVG: "0% 100%" }, "-=0.6");
		that.navBtnTl.fromTo(closeUp, 0.6, { x: "-=10", y: "+=10", drawSVG: "100% 100%" }, { x: "0", y: "0", drawSVG: "0% 100%" }, "-=0.6");
		that.navBtnTl.pause();
	}


	events() {

		let that = this;

		that.navBtn.click(function(){
			if(that.navMenu.hasClass("active")){
				console.log("close menu");
				that.navBtnTl.reverse();
				that.navMenuTl.reverse();
			} else {
				console.log("open menu");
				that.navBtnTl.play();
				that.navMenuTl.play();
			}

		})

	}

	navBtnState(navBtn) {
		$(navBtn).addClass("active");
		$('body').addClass("overflow-hidden");
	}

	deactivateMobileMenu(navBtn) {
		$(navBtn).removeClass("active");
		$('body').removeClass("overflow-hidden");
	}

}

export default MobileNav;