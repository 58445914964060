class ImagesSlider {

  constructor(slider) {
    console.log(slider);
    this.slider = slider;
    this.init();
  }

  init() {
    let swiper = this.slider.querySelector('.swiper-container');
    console.log(swiper);
    new Swiper(swiper, {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 30,
      speed: 600,
      loop: false,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true
      },
      navigation: {
        nextEl: this.slider.querySelector('.swiper-button-next'),
        prevEl: this.slider.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: this.slider.querySelector('.swiper-pagination'),
        clickable: true,
      },

    });
  }
}

export default ImagesSlider;
