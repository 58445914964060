class Fancybox {

  constructor(container) {
    let selector = "." + container.getAttribute("data-selector");
    $(container).fancybox({
      selector: selector,
      buttons: ["close"],
      afterShow: function (instance, current) {
        let caption = $('.fancybox-caption__body');
        let captionHeight = caption.height();
        let img = $('.fancybox-slide--current .fancybox-content');
        let imgHeight = img.height();
        let imgWidth = img.width();
        let windowHeight = $(window).height();
        let windowWidth = $(window).width();
        let offset = 44;
        let verticalSpacing = (windowHeight - imgHeight) / 2;
        let bottom = ((windowHeight - imgHeight) / 2) - offset;
        let left = ((windowWidth - imgWidth) / 2) - 0;
        // console.log(verticalSpacing, captionHeight, imgHeight);
        caption.css({
          'bottom': bottom + 'px',
          'left': left + 'px',
          'width': imgWidth + 'px'
        });

        if ((captionHeight + offset) > verticalSpacing) {
          // console.log("not enough space for caption: ", captionHeight - verticalSpacing);
          caption.css({
            'bottom': verticalSpacing + 'px',
            'left': left + 'px',
            'transform': 'none',
            'width': imgWidth + 'px'
          });
          caption.addClass("overlay");
        }
      },
    });

  }
}

export default Fancybox;
