// import waypoints from '../../../node_modules/waypoints/lib/noframework.waypoints';

class Youtube {

  constructor(container) {
    this.c = container;
    this.triggerWhenInViewPort();
  }

  // Load video when in viewport
  triggerWhenInViewPort() {
    ScrollTrigger.create({
      trigger: this.c,
      start: "top bottom",
      once: true,
      // markers: true,
      onEnter: () => this.loadYouTubeiFrame(),
    });
  }

  // Construct the iframe
  loadYouTubeiFrame() {
    let videoContainer = this.c.querySelector('.youtube__container');
    let URL = videoContainer.getAttribute('data-href');
    let htm = '<iframe class="embed-responsive-item" width="100%" height="auto" src="' + URL + '" frameborder="0" allowfullscreen ></iframe>';
    videoContainer.innerHTML = htm;
  }
}

export default Youtube;
