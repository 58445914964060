class Box {

  constructor(container) {

    gsap.registerPlugin(ScrollToPlugin, CSSRulePlugin);
    this.box = container;
    this.tl = this.createTimeline();
    this.init(container);

  }


  init() {

    // Get the nav item that triggers the submenu
    let triggerButton = this.box.querySelector('.box__footer .btn__collapse');

    // Handle the click event / toggle navigation
    triggerButton.addEventListener('click', (e) => {
      e.preventDefault();
      if (this.box.classList.contains("active")) {
        this.tl.reverse();
        // Get offset top of box
        let scrollDest = this.box.offsetTop
        gsap.to(window, 1.2, {
          scrollTo: scrollDest
        });
      } else {
        this.tl.play();
      }
    })
  }


  createTimeline() {

    let
      boxBody = this.box.querySelector('.box__body'),
      btn = this.box.querySelector('.btn__collapse .icon'),
      tl = gsap.timeline({
        onStart: () => {
          this.box.classList.add('active');
        },
        onReverseComplete: () => {
          this.box.classList.remove('active');
        }
      });

    tl.to(boxBody, {
      duration: 0.001,
      height: "auto"
    });
    tl.from(boxBody, {
      duration: 1.2,
      height: 0
    }, "a");
    tl.to(btn, {
      duration: 0.6,
      rotate: "+=180deg"
    }, "a");
    tl.pause();

    return tl;

  }

}

export default Box;
