class Testimonials {

  constructor(container) {
    this.c = container
    this.fixTextDifference()
    this.initSlider();
  }


  fixTextDifference() {

    // Abort this function if this.c has no class with "testimonials--career"
    if (!this.c.classList.contains('testimonials--career')) {
      return false;
    }

    // Get text lengths
    let slides = this.c.querySelectorAll('.testimonials__quote');
    let slidesArray = [...slides];
    let textLengths = slidesArray.map((val) => val.innerHTML.length);

    // Get shortest Text
    Array.min = function (array) {
      return Math.min.apply(Math, array);
    };
    let min = Array.min(textLengths);
    // console.log("shortest Text: ", min);

    // Get default font size
    let oldSize = parseInt(window.getComputedStyle(document.querySelector('.testimonials__quote')).fontSize);
    // console.log("old font size: ", oldSize);

    // Calculate and set new font sizes (if not smaller than 16px)
    textLengths.forEach((t, i) => {
      let factor = parseFloat((min / t).toFixed(2));
      let newSize = oldSize * factor;
      if (newSize < 16) newSize = 16;
      slides[i].style.fontSize = newSize + "px";
      // console.log(newSize);
    });

  }


  initSlider() {
    let numOfSlides = this.c.querySelectorAll('.swiper-slide').length;
    let swiper = this.c;
    if (numOfSlides > 1) {

      let testimonialSwiper = new Swiper(swiper, {
        speed: 600,
        spaceBetween: 30,
        loop: false,
        autoplay: {
          delay: 1000,
          disableOnInteraction: true,
          stopOnLastSlide: true
        },
        navigation: {
          nextEl: swiper.querySelector('.swiper-button-next'),
          prevEl: swiper.querySelector('.swiper-button-prev'),
        },
        pagination: {
          el: swiper.querySelector('.swiper-pagination'),
          clickable: true
        },
        on: {
          init: function () {
            let switches = swiper.querySelectorAll('.avatars__item');
            // console.log(switches);
            gsap.defaults({
              ease: "power2.out"
            });
            gsap.set(switches, {
              opacity: 0
            });
            gsap.set(switches[0], {
              opacity: 1
            });
            switches[0].classList.add("active");
          },
          slideNextTransitionStart: function () {
            let switches = swiper.querySelectorAll('.avatars__item');
            let targetSwitch = switches[parseInt(this.realIndex)];
            let last = switches[parseInt(this.realIndex) - 1];

            gsap.to(last, {
              duration: 0.4,
              opacity: 0,
              x: "-=15"
            })

            gsap.fromTo(targetSwitch, {
              opacity: 0,
              x: "+=15"
            }, {
              duration: 0.7,
              opacity: 1,
              x: "0"
            })
            last.classList.remove('active')
            targetSwitch.classList.add('active')
          },
          slidePrevTransitionStart: function () {
            let switches = swiper.querySelectorAll('.avatars__item');
            let targetSwitch = switches[parseInt(this.realIndex)];
            let last = switches[parseInt(this.realIndex) + 1];

            gsap.to(last, {
              duration: 0.4,
              opacity: 0,
              x: "+=10"
            })

            gsap.fromTo(targetSwitch, {
              opacity: 0,
              x: "-=15"
            }, {
              duration: 0.7,
              opacity: 1,
              x: "0"
            })
            last.classList.remove('active')
            targetSwitch.classList.add('active')
          },
        }
      });
    }
  }
}

export default Testimonials;
