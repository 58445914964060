export default class Sfcard {

  constructor(container) {

    gsap.registerPlugin(ScrollToPlugin, CSSRulePlugin);
    gsap.defaults({
      ease: "power4.inOut"
    });
    this.sfcard = container;
    this.tl = this.createTimeline();
    this.init(container);

  }


  init() {

    // Get the nav item that triggers the submenu
    let triggerButton = this.sfcard.querySelector('.sf-card__footer .btn__collapse');

    // Handle the click event / toggle navigation
    triggerButton.addEventListener('click', (e) => {
      e.preventDefault();
      if (this.sfcard.classList.contains("active")) {
        this.tl.reverse();
        // Get offset top of sfcard
        let scrollDest = this.sfcard.offsetTop
        gsap.to(window, 1.2, {
          scrollTo: scrollDest
        });
      } else {
        this.tl.play();
      }
    })
  }


  createTimeline() {

    let
      sfcardBody = this.sfcard.querySelector('.sf-card__collapsable'),
      btn = this.sfcard.querySelector('.btn__collapse .icon'),
      tl = gsap.timeline({
        onStart: () => {
          this.sfcard.classList.add('active');
        },
        onReverseComplete: () => {
          this.sfcard.classList.remove('active');
        }
      });

    tl.to(sfcardBody, {
      duration: 0.001,
      height: "auto"
    });
    tl.from(sfcardBody, {
      duration: 1.2,
      height: 0
    }, "a");
    tl.to(btn, {
      duration: 0.6,
      rotate: "+=180deg"
    }, "a");
    tl.pause();

    return tl;

  }

}
